<template>
  <div class="container">
    <eventApp />
    <div class="title">Aktualności</div>
    <div class="articleBox">
      <div class="itemBox" v-for="(article, index) in this.articleList" :key="index">
        <div class="itemBoxWrapper">
          <div class="itemPhoto">
            <div class="box-image-content"><img :src="article.thumbPhoto" alt="" /></div>
          </div>
          <div class="itemContent">
            <div class="addingData">dodano: {{article.addingData}}</div>
            <div><h2>{{article.title}}</h2></div>
            <div class="shortMsg" v-html="article.shortMsg" />
            <div class="longMsg" v-if="article.longMsg">
              <br />
              <a :href="`/artykul/${article.id }`"> Zobacz więcej... </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import eventApp from '../components/eventApp.vue';

export default {
  name: 'ArticleList',
  data() {
    return {
      articleList: [],
      eventList: [],
    };
  },
  components: {
    eventApp,
  },
  mounted() {
    this.getArticleList();
  },
  methods: {
    getArticleList() {
      axios.get('https://rakowistop.pl/api/api.php?action=article', this.data, {
      }).then((res) => {
        this.articleList = res.data.rows;
      }).catch((err) => {
        console.log(err.response);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1140px;
  margin: auto;
}

img {
    width: 100%;
}

.articleBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  & .itemBox {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 30px 10px;

    @media (min-width: 768px) {
      width: 50%;
    }

    & .itemBoxWrapper {
      border: 1px solid #dee2e6;
      height: 550px;
      box-shadow: 5px 7px 5px 0 rgba(0, 1, 1, 0.1);

      & .itemPhoto {
        overflow: hidden;
        height: 300px;
      }

      & .itemContent {
        padding: 10px;

        & h2 {
          text-transform: uppercase;
          padding-bottom: 10px;
          font-size: 21px;
          font-weight: 900;
        }
        & .shortMsg {
          line-height: 25px;
        }

        & .longMsg {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          a {
            color: var(--color-green);
            font-weight: 600;
          }
        }
      }
    }

  }
}

.box-image-content {
  transition: 0.3s ease-out;
}

.box-image-content:hover {
  transform: scale(1.1);
}

  .addingData {
    text-align: right;
    font-size: 14px;
    padding: 10px 0px 0px 0px;
  }

</style>
