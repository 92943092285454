import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'start',
    component: Home,
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component() {
      return import(/* webpackChunkName: "about" */ '../views/ContactView.vue');
    },
  },
  {
    path: '/ofundacji',
    name: 'ofundacji',
    component() {
      return import('../views/AboutView.vue');
    },
  },
  {
    name: 'artykul',
    path: '/artykul/:id', // Używamy :param jako placeholdera dla parametru
    component() {
      return import('../views/ArticleView.vue');
    },
  },
  {
    path: '/coi',
    name: 'coi',
    component() {
      return import('../views/CoiView.vue');
    },
  },
  {
    path: '/naszeRealizacje',
    name: 'naszeRealizacje',
    component() {
      return import('../views/RealizationsView.vue');
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
