import { createApp } from 'vue';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import VueSmoothScroll from 'v-smooth-scroll';
import VueCookies from 'vue-cookies';

import axios from 'axios';

/* Import App */
import App from './App.vue';
import router from './router';

const app = createApp(App);

library.add(fas, fab, far);
app.config.globalProperties.globalUrl = 'https://rakowistop.pl';
app.config.globalProperties.$apiUrl = 'https://rakowistop.pl/api/api.php'; // Globalna właściwość URL
app.config.globalProperties.$axios = axios;
app.use(VueCookies);
app.use(router);
app.use(VueSmoothScroll);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app', AOS.init(), Fancybox.bind('[data-fancybox]'));
