<template>
  <div class="infoTop">
    <div><font-awesome-icon icon="phone-flip" /><a href="tel:32 423 07 36"> 32 423 07 36 {{ reversedMessage }}</a></div>
    <div><font-awesome-icon icon="mobile" /><a href="tel:781 711 589"> 781 711 589 {{ reversedMessage }}</a></div>
    <div>
      <font-awesome-icon icon="envelope" /><a href="mailto:rakowistop@gmail.com">
        rakowistop@gmail.com</a>
    </div>
    <div><font-awesome-icon icon="location-dot" /> ul. Energetyków 46, 44-200 Rybnik</div>
    <div>
      <a href="https://www.facebook.com/fundacjarakowistop" target='_blank' rel='noopener'>
        <font-awesome-icon icon="fa-brands fa-facebook" /><span>facebook</span></a>
    </div>
  </div>
  <nav>
    <ul class="menuBox" data-aos="fade-up">
      <li><font-awesome-icon icon="bars" @click="showMenu()" class="navbar-toggle" /></li>
      <li :class="[{ active: showMenuMobile === 1 }, 'navbar-links']">
        <a href="/"><font-awesome-icon icon="fa-solid fa-house-chimney" class="menuBoxIco" />
          <span>Aktualności</span></a
        >
      </li>
      <li :class="[{ active: showMenuMobile === 1 }, 'navbar-links']">
        <a href="/ofundacji"><font-awesome-icon icon="tasks" class="menuBoxIco" /> <span>O fundacji</span></a
        >
      </li>
      <li :class="[{ active: showMenuMobile === 1 }, 'navbar-links']">
        <a href="/coi"><font-awesome-icon icon="user-friends" class="menuBoxIco" /> <span>COI</span></a
        >
      </li>
      <li :class="[{ active: showMenuMobile === 1 }, 'navbar-links']">
        <a href="/naszeRealizacje"><font-awesome-icon icon="coins" class="menuBoxIco" /> <span>Nasze realizacje</span></a
        >
      </li>
      <li :class="[{ active: showMenuMobile === 1 }, 'navbar-links']">
        <a href="/kontakt"><font-awesome-icon icon="phone" class="menuBoxIco" /> <span>Kontakt</span></a
        >
      </li>
    </ul>
  </nav>

  <div class="banner">
    <div class="container" style="max-width:90%;">
      <img src="https://rakowistop.pl/files/pozostale/banner.png" alt="">
    </div>
  </div>
  <!-- <router-link to="/">Strona startowa</router-link> |
  <router-link to="/about">O Nas</router-link> -->
  <LoaderPage v-if="loading" />
  <router-view v-else />
</template>

<script>
// import modalApp from './components/modalApp.vue';
import { Fancybox } from '@fancyapps/ui';
import LoaderPage from './components/LoaderPage.vue';

export default {
  name: 'App',
  data() {
    return {
      loading: true,
      showMenuMobile: 0,
      modalImg: require('./assets/img/zrzutka1.jpg'),
    };
  },
  components: {
    LoaderPage,
  },
  methods: {
    onLoad() {
      this.loading = false;
    },

    showMenu() {
      if (this.showMenuMobile === 1) {
        this.showMenuMobile = 0;
      } else {
        this.showMenuMobile = 1;
      }
    },
  },
  mounted() {
    window.addEventListener('load', this.onLoad);

    if (!this.$cookies.get('modal')) {
      this.$cookies.set('modal', 'accept', '1d');

      Fancybox.show([{
        src: `<div class="modal-content">
                  <img src="${this.modalImg}" alt="Obrazek">
                </div>`,
        type: 'html',
      }]);
    }
  },
  beforeUnmount() {
    window.removeEventListener('load', this.onLoad);
  },
  computed: {

  },
};
</script>

<style lang="scss">

:root {
  --color-green: #126152;
  --nav-color: #a62938;
}

*,
body {
  font-family: "Lato", serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1140px;
  margin: auto;
}

.infoTop {
  background-color: var(--color-green);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  div,
  a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  div {
    margin: 5px 0px;
  }

  div svg {
    margin-right: 5px;
  }

  div span {
    display: none;
  }

  div:nth-child(2n) {
    padding: 0px 25px;
  }
}

nav {
  background-color: var(--nav-color);
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .menuBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      width: 90%;
      margin: 20px 0px;
      position: relative;
    }

    li {
      list-style-type: none;
      padding: 0px 15px;

      svg {
        transition: 0.7s ease-out;
      }

      &:hover svg {
        color: var(--nav-color);
      }

      @media (max-width: 600px) {
        padding: 10px 0px;
      }
    }

    li a {
      display: flex;
      align-items: center;
    }

    li .navbar-toggle {
      display: none;

      @media (max-width: 600px) {
        display: block;
        font-size: 30px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 5px;
        margin-right: 30px;
      }
    }

    li.navbar-links {
      &.active {
        display: block;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }

    .menuBoxIco {
      font-size: 40px;
      color: var(--color-green);
    }

    span {
      font-size: 16px;
      font-weight: 600;
      margin-left: 15px;
      color: #000;
    }
  }
}

.banner {
  img {
    width: 100%;
    height: auto;
  }
}

footer {
  margin-top: auto;
  height: 50px;
  background-color: var(--color-green);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.title {
  font-size: 40px;
  font-weight: 600;
  color: var(--nav-color);

  &:after {
    content: '';
    display: block;
    margin-top: 5px;
    border-bottom: 1px solid var(--nav-color);
  }
}
</style>
