<template>
  <div class="title">Najbliższe wydarzenia</div>
  <div class="eventWrapper">
    <a v-for="(item, i) in eventList" :key="i" data-fancybox="gallery" v-bind:href="globalUrl + item.foto" aria-label='gallery'><img class="thumb-nail" v-bind:src="globalUrl + item.foto" alt=""></a>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'eventApp',
  data() {
    return {
      globalUrl: this.globalUrl,
      eventList: [],
    };
  },
  components: {
  },
  mounted() {
    this.getNearestEvents();
  },
  methods: {
    getNearestEvents() {
      axios.get('https://rakowistop.pl/api/api.php?action=nearestEvents', this.data, {
      }).then((res) => {
        this.eventList = res.data.rows;
      }).catch((err) => {
        console.log(err.response);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .eventWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 20px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    img {
      width: 100%;
      height: auto;
    }
    .thumb-nail {
      padding: 0.25rem;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 0.25rem;
      }
  }
</style>
